<template>
  <div style="min-height: 100vh">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="contents px-15">
      <div class="back" @click="back">
        <van-image
          width="9px"
          height="15px"
          :src="require('@/assets/cls/arrow-l.png')"
        />
        <span>{{ $t("withdraw.return") }}</span>
      </div>
      <div class="btn">
        <div
          :class="1 == i ? 'current' : ''"
          @click="
            i = 1;
            amount = null;
          "
        >
          USDT
        </div>
        <div
          :class="2 == i ? 'current' : ''"
          @click="
            i = 2;
            amount = null;
          "
        >
          UNA
        </div>
        <div
          :class="3 == i ? 'current' : ''"
          @click="
            i = 3;
            amount = null;
          "
        >
          BNB
        </div>
      </div>
      <div class="money">{{ $t("withdraw.Withdrawal") }}</div>
      <van-field
        v-model="amount"
        type="number"
        :placeholder="(i == 1 ? `${$t('withdraw.current')}:${USDT.amount}USDT` : (i == 2 ? `${$t('withdraw.current')}:${CLS.amount}UNA` : `${$t('withdraw.current')}:${BNB.amount}BNB`))"
        class="input"
        :border="false"
        @input="onInput"
      >
        <template #extra>
          <div class="all">
            <div class="name">{{ (i == 1 ? "USDT" : (i == 2 ? "UNA" : "BNB")) }}</div>
            <div class="line"></div>
            <div class="all_btn" @click="allHandle">
              {{ $t("withdraw.all") }}
            </div>
          </div>
        </template>
      </van-field>
      <div class="lowest">
        <span style="margin-right: 27px"
          >{{ $t("withdraw.minimum") }}：{{ (i == 1 ? setting.withdraw_min_number : (i == 2 ? (Number(setting.withdraw_min_number) + Number(fee_num)) : setting.withdraw_bnb_min_fee)) }}</span
        >
        <span v-if="!disabledOk "
          >{{ $t("withdraw.commission") }}：{{i == 1 || i == 3 ? Number(serviceCharge).toFixed(4) : (Number(serviceCharge + Number(fee_num)).toFixed(4))  }}{{ (i == 1 ? "USDT" : (i == 2 ? "UNA" : "BNB")) }}</span
        >
      </div>
      <div class="num" v-if="!disabledOk && arrivalAmount > 0">
        {{ $t("withdraw.Account") }}：<span style="font-weight: 700"
          >{{ (i == 1 ? "USDT" : (i == 2 ? "UNA" : "BNB")) }} {{ arrivalAmount.toFixed(4) }}</span
        >
      </div> 
      <div style="margin-bottom: 274px" class="tq">
        <van-button
          :hairline="true"
          :disabled="disabledOk"
          :round="true"
          color="#ae90fb"
          style="width: 100%; border: 1px solid #000"
          @click="withDrawHandle"
          >{{ $t("withdraw.extract") }}</van-button
        >
      </div>
      <div class="record" @click="toRecord">
        {{ $t("withdraw.record") }}
      </div>
    </div>

    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import asidePopup from "@/components/common/asidePopup";

import abi_G from "../common/abi/abi_G.json";

export default {
  components: { asidePopup },
  data() {
    return {
      asideFlag: false,
      disabledOk: true,
      amount: null,
      blance: null,
      creditedNum: null, //预计到帐
      minNumber: 0, //最低提币数量
      fee: "", //手续费
      isloading: false,
      loading: false,
      i: 1,
      setting: [],
      USDT: {},
      BNB: {},
      CLS: {},
      setting: {},
      serviceCharge: 0,
      arrivalAmount: 0,
      wallet_address: "",
      withdraw_contract: "",
      fee_num:'',
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  async created() {
    this.$store.dispatch("getSetting"); //网站配置
    // this.getmyEarn();
    await this.getMyEarn(); //我的收益
    this.getsetting(); //提币相关配置
    // this.settingHandle();
  },
  watch:{
    amount:function (one,eng){
      this.onInput(one)
    }
  },
  methods: {
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    back() {
      this.$router.back();
    },
    toRecord(e) {
      this.$router.push(`/activeRecord?type=6`);
    },
    // 资产列表
    getMyEarn() {
      this.$axios.get(this.$api.myEarn, {}).then((res) => {
        this.USDT = res.data.USDT;
        this.BNB = res.data.BNB;
        this.CLS = res.data.TOKEN;
      });
    },
    // 输入框全部按钮
    allHandle() {
      if (this.i == 1) {
        this.amount = this.USDT.amount;
      } else if (this.i == 2) {
        this.amount = this.CLS.amount;
      } else {
        this.amount = this.BNB.amount;
      }
    },
    settingHandle() { 
      this.wallet_address = this.$store.state.walletAddress;
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach((item) => {
        if (item.key == "usdt_contract_address"){//USDT合约地址
          this.contract_address = item.value;
        }

        if (item.key == "withdraw_contract"){ //提现合约地址
          this.withdraw_contract = item.value;
        }
      });
    },
    // 提取
    withDrawHandle() {
      this.$dialog
        .confirm({
          title: this.$t("withdraw.extract"),
          message: `${this.$t("withdraw.message")}$${this.amount}?`,
          confirmButtonColor: "#00A8FF",
          confirmButtonText: this.$t("withdraw.submit"),
          cancelButtonText: this.$t("withdraw.cancel"),
        })
        .then(() => {
          this.$axios
            .post(this.$api.withdraw, { type: this.i, amount:Number(this.amount) })
            .then((res) => {
              if(res.code == 200){
                this.$toast('OK');
                this.getMyEarn()
                this.amount = null
              }
              if(res.code == 401){
                this.$toast(res.msg);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    all() {
      this.amount = this.blance;
    },
    onInput(e) {
      if (this.i == 1 || this.i == 2) {
        if (this.amount >= Number(this.setting.withdraw_min_number)) {
          this.disabledOk = false;
        } else {
          this.disabledOk = true;
        }
      }
      else if (this.i == 3) {
        if (this.amount >= Number(this.setting.withdraw_bnb_min_fee)) {
          this.disabledOk = false;
        } else {
          this.disabledOk = true;
        }
      }


      if(this.i == 1){
        this.serviceCharge = e * this.setting.withdraw_usdt_fee_ratio;
      } else if(this.i == 2){
        this.serviceCharge = e * this.setting.withdraw_fee_ratio;
      }else{
        this.serviceCharge = e * this.setting.withdraw_bnb_fee_ratio;
      }
      this.arrivalAmount = this.i==1? this.amount - this.serviceCharge:this.amount - (Number(this.serviceCharge + Number(this.fee_num)).toFixed(2)) ;
    },
    //我的收益
    getmyEarn() {
      this.loading = true;
      this.$axios.get(this.$api.myEarn, {}).then((res) => {
        this.blance = res.data.balance;
        this.loading = false;
      });
    },
    //网站配置
    getsetting() {
      let info = JSON.parse(this.$store.state.setting);
      info.forEach((item) => {
        if (item.key == "usdt_contract_address"){//USDT合约地址
          this.contract_address = item.value;
        }

        if (item.key == "withdraw_contract"){ //提现合约地址
          this.withdraw_contract = item.value;
        }
        if (item.key == "withdraw_min_number") { //最小提币数量
          this.setting.withdraw_min_number = item.value;
        }
        if (item.key == "withdraw_token_fee_ratio") { //提币百分比手续费（小数形式）
          this.setting.withdraw_fee_ratio = item.value;
        }
        if(item.key == 'withdraw_usdt_fee_ratio'){
          this.setting.withdraw_usdt_fee_ratio = item.value;
        }
        if(item.key == 'withdraw_bnb_fee_ratio'){
          this.setting.withdraw_bnb_fee_ratio = item.value;
        }
        if(item.key == 'withdraw_token_fee_num'){
          this.fee_num = item.value
        }
        if(item.key == 'withdraw_bnb_min_fee'){
          this.setting.withdraw_bnb_min_fee = item.value;
        }
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.back {
  margin: 13px 0 28px;
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-left: 6px;
  }
}
.btn {
  height: 40px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 27px 40px;
  display: flex;
  div {
    width: 50%;
    height: 100%;
    color: #000;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    align-items: center;
  }
  .current {
    background: #8f92fa;
    color: #ffffff;
  }
}
.money {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-bottom: 6px;
}
.input {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px 10px 10px 0;
  .all {
    display: flex;
    align-items: center;

    .name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .line {
      height: 16px;
      border-right: 1px solid #999999;
      margin: 0 11px;
    }
    .all_btn {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #8d91f9;
    }
  }
}
.lowest {
  margin: 5px 0 74px;
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.num {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.record {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8f92fa;
  text-align: center;
}

.conbtn {
  margin-top: 5px;
  height: 35px;
  border-radius: 5px;
  font-size: 13px;
}
.reminder {
  min-height: 55px;
  background: rgba(186, 197, 255, 0.1);
  border-radius: 10px;
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.tq{
    border: 1px solid #000 ;
    border-radius: 5px !important; 
  button{
    // border: 1px solid #000 !important;
    border-radius: 5px !important; 
  }
}
</style>